// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landscape-js": () => import("./../../../src/pages/landscape.js" /* webpackChunkName: "component---src-pages-landscape-js" */),
  "component---src-pages-nature-js": () => import("./../../../src/pages/nature.js" /* webpackChunkName: "component---src-pages-nature-js" */),
  "component---src-pages-people-js": () => import("./../../../src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-urban-js": () => import("./../../../src/pages/urban.js" /* webpackChunkName: "component---src-pages-urban-js" */)
}

